export const Flags: Record<string, string> = {
    a: `<path d="M0 0H100L75 50L100 100H0V0Z" fill="#EFEBDC" /><path d="M50 0H100L75 50L100 100H50V0Z" fill="#0C4A6E" />`,
    b: `<path d="M0 0H100L75 50L100 100H0V0Z" fill="#DC2626" />`,
    c: `<rect width="100" height="100" fill="#0C4A6E" /><rect y="20" width="100" height="60" fill="#EFEBDC" /><rect y="40" width="100" height="20" fill="#DC2626" />`,
    d: `<rect width="100" height="100" fill="#FACC15" /><rect y="20" width="100" height="60" fill="#0C4A6E" />`,
    e: `<rect width="100" height="100" fill="#DC2626" /><rect width="100" height="50" fill="#0C4A6E" />`,
    f: `<rect y="7.62939e-06" width="100" height="100" fill="#EFEBDC" /><path d="M6.10352e-05 50L50.0001 8.6849e-07L100 50L50.0001 100L6.10352e-05 50Z" fill="#DC2626" />`,
    g: `<rect width="100" height="100" fill="#0C4A6E" /><rect width="16.6667" height="100" fill="#FACC15" /><rect x="66.3333" width="16.6667" height="100" fill="#FACC15" /><rect x="32.3333" width="16.6667" height="100" fill="#FACC15" />`,
    h: `<rect width="100" height="100" fill="#DC2626" /><rect width="50" height="100" fill="#EFEBDC" />`,
    i: `<rect width="100" height="100" fill="#FACC15" /><rect x="25" y="25" width="50" height="50" rx="25" fill="#18181B" />`,
    j: `<rect width="100" height="100" fill="#0C4A6E" /><rect y="33.3333" width="100" height="33.3333" fill="#EFEBDC" />`,
    k: `<rect width="100" height="100" fill="#0C4A6E" /><rect width="50" height="100" fill="#FACC15" />`,
    l: `<rect width="100" height="100" fill="#18181B" /><rect y="50" width="50" height="50" fill="#FACC15" /><rect x="50" width="50" height="50" fill="#FACC15" />`,
    m: `<rect y="3.05176e-05" width="100" height="100" fill="#0C4A6E" /><path d="M0.00012207 1.33514e-05V13L37.0001 50L0.00012207 87V100H13.0001L50.0001 63L87.0001 100H100V87L63.0001 50L100 13V0H87.0001L50.0001 37L13.0001 1.33514e-05H0.00012207Z" fill="#EFEBDC" />`,
    n: `<rect width="100" height="100" fill="#EFEBDC" /><rect x="75" y="75" width="25" height="25" fill="#0C4A6E" /><rect x="75" y="25" width="25" height="25" fill="#0C4A6E" /><rect x="50" y="50" width="25" height="25" fill="#0C4A6E" /><rect x="25" y="75" width="25" height="25" fill="#0C4A6E" /><rect y="50" width="25" height="25" fill="#0C4A6E" /><rect x="25" y="25" width="25" height="25" fill="#0C4A6E" /><rect x="50" width="25" height="25" fill="#0C4A6E" /><rect width="25" height="25" fill="#0C4A6E" />`,
    o: `<rect width="100" height="100" fill="#FACC15" /><path d="M100 0L0 0L100 100V0Z" fill="#DC2626" />`,
    p: `<rect width="100" height="100" fill="#0C4A6E" /><rect x="33" y="33" width="33.3333" height="33.3333" fill="#EFEBDC" />`,
    q: `<rect width="100" height="100" fill="#FACC15" />`,
    r: `<rect width="100" height="100" fill="#DC2626" /><path d="M100 60V40H60V0H40V40H0V60H40V100H60V60H100Z" fill="#FACC15" />`,
    s: `<rect width="100" height="100" fill="#EFEBDC" /><rect x="33" y="33" width="33.3333" height="33.3333" fill="#0C4A6E" />`,
    t: `<rect width="100" height="100" fill="#DC2626" /><rect x="66.66" width="33.3333" height="100" fill="#0C4A6E" /><rect x="33.33" width="33.3333" height="100" fill="#EFEBDC" />`,
    u: `<rect width="100" height="100" fill="#DC2626" /><rect y="50" width="50" height="50" fill="#EFEBDC" /><rect x="50" width="50" height="50" fill="#EFEBDC" />`,
    v: `<rect y="1.52588e-05" width="100" height="100" fill="#EFEBDC" /><path d="M0 1.33514e-05V13L37 50L0 87V100H13L50 63L87 100H100V87L63 50L100 13V0H87L50 37L13 1.33514e-05H0Z" fill="#DC2626" />`,
    w: `<rect width="100" height="100" fill="#0C4A6E" /><rect x="20" y="20" width="60" height="60" fill="#EFEBDC" /><rect x="40" y="40" width="20" height="20" fill="#DC2626" />`,
    x: `<rect width="100" height="100" fill="#EFEBDC" /><path d="M100 60V40H60V0H40V40H0V60H40V100H60V60H100Z" fill="#0C4A6E" />`,
    y: `<rect width="100" height="100" fill="#FACC15" /><path d="M100 0V20L20 100H0L100 0Z" fill="#DC2626" /><path d="M80 0H60L0 61V80L80 0Z" fill="#DC2626" /><path d="M40 0H20L0 21V40L40 0Z" fill="#DC2626" /><path d="M100 40V61L59 100H40L100 40Z" fill="#DC2626" /><path d="M79 100H100V81L79 100Z" fill="#DC2626" />`,
    z: `<rect width="100" height="100" fill="#18181B" /><path d="M100 0L0 0L50 50L100 0Z" fill="#FACC15" /><path d="M100 100L50 50L0 100H100Z" fill="#DC2626" /><path d="M100 0L50 50L100 100V0Z" fill="#0C4A6E" />`,
};

export function generateSvg(value: string, spacing: number = 10, repeatable: boolean = false): string {
    const available = Object.keys(Flags);
    let svg = "";
    let x = repeatable ? spacing / 2 : 0;

    for (let i = 0; i < value.length; i++) {
        const s = value[i];
        if (!available.includes(s)) continue;

        svg += `<svg x="${x}">${Flags[s]}</svg>`;
        x += 100 + (i < value.length - 1 ? spacing : 0);
    }

    if (repeatable) {
        svg += `<svg x="${x}" width="${spacing / 2}" />`;
        x += spacing / 2;
    }

    return `<svg width="${x}" height="100" viewBox="0 0 ${x} 100" fill="none" xmlns="http://www.w3.org/2000/svg">${svg}</svg>`;
}