
import { defineComponent, reactive, ref, watch } from "vue";

import { generateSvg } from "@/script/Flag";

export default defineComponent({
    setup() {
        const svg = ref("");

        const options = reactive({
            input: "",
            repeatable: false,
            spacing: 10,
        });

        const getSvgAsBase64 = () => { return btoa(unescape(encodeURIComponent(svg.value))) };

        watch(options, () => {
            svg.value = generateSvg(options.input.toLowerCase(), options.spacing, options.repeatable);
        }, { deep: true });

        return {
            getSvgAsBase64,
            options,
            svg,
        };
    },
});
